<template>
  <div class="columns is-gapless is-centered is-mobile">
    <div class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen is-4-fullhd mb-4">
      <div class="container has-text-centered pt-6 px-5">
        <h3 class="is-size-5 is-2 mb-5">
          Thank you for joining our lucky draw!
        </h3>
        <div class="is-size-7 mb-3">
          Once you're done tagging 3 friends on our Instagram post, click the button below to create a bill-split event and follow the steps below the event page. It doesn't have to be a real event, just make up some names!
        </div>
        <div class="is-size-7 mb-3">
          After clicking "Start now", scroll down and you will see this blue box to join the lucky draw. It should take less than 2 minutes!
        </div>
        <figure class="image lucky-draw-image-modifier">
          <img src="../assets/lucky_draw/bbsldhomepage.png">
        </figure>
        <button @click="$router.push({ name: 'Start' })" class="button is-large is-rounded is-info start-now-button-modifier mt-5">
          Start now
        </button>
      </div>
      <div class="container has-text-centered pt-0 px-5">
        <div class="pt-6 my-6"></div>

        <div class="container">
          <div class="is-size-7 has-text-grey-light">
            Made by M Afiq, out of his frustration of splitting bills.
          </div>
          <div
          class="is-size-7 mt-4 is-underlined is-unselectable is-clickable"
          >
          <a
          class="has-text-grey-light"
          href="https://www.linkedin.com/in/m-afiq/"
          target="_blank">
          <u>LinkedIn</u>
          </a> 
          </div>
          <div @click="togglePrivacyNotice()" class="is-size-7 has-text-grey-light mt-2 is-underlined is-unselectable is-clickable">
            <u>Privacy Policy</u>
          </div>
        </div>
      </div>
      <div class="container pt-0 mb-5 m-0">
        <article v-show="showPrivacyNotice" class="message pt">
          <div class="message-body">
            <PrivacyNotice />
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import PrivacyNotice from '@/components/PrivacyNotice'


export default {
  components: {
    PrivacyNotice
  },
  data() {
    return {
      showPrivacyNotice: false
    }
  },
  methods: {
    togglePrivacyNotice() {
      this.showPrivacyNotice = !this.showPrivacyNotice
    }
  }
}
</script>

<style>
.start-now-button-modifier {
  background: rgb(0,176,255) !important;
  background: linear-gradient(156deg, rgba(0,176,255,1) 0%, rgba(0,122,203,1) 0%, rgba(0,134,223,1) 100%) !important;
  border: 0 !important;
}

.lucky-draw-image-modifier {
  max-width: 400px;
  margin: auto;
}
</style>